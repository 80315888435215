import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { AlertDialogData } from '@@core/models/alert-dialog.model';
import { AlertDialogComponent, ConfirmationDialogComponent, ConfirmationDialogData } from '@@shared/dialog';

@Injectable({
	providedIn: 'root'
})
export class ConfirmationDialogService {
	readonly #defaultDialogConfig: MatDialogConfig<ConfirmationDialogData> = {
		panelClass: ['confirmation-dialog', 'new-design'],
		autoFocus: false,
		data: {
			title: ''
		},
		backdropClass: 'cdk-overlay-transparent-backdrop'
	};

	// Services declaration
	readonly #dialog = inject(MatDialog);

	openDialog(dialogConfig: MatDialogConfig<ConfirmationDialogData>, afterClosed?: (confirmed: boolean) => void): void {
		const dialogRef = this.#dialog.open(ConfirmationDialogComponent, { ...this.#defaultDialogConfig, ...dialogConfig });

		dialogRef.afterClosed()
			.subscribe((confirmed: boolean) => {
				afterClosed?.(confirmed);
			});
	}

	showAlert(config: AlertDialogData): MatDialogRef<AlertDialogComponent, void> {
		return this.#dialog.open<AlertDialogComponent, AlertDialogData, void>(AlertDialogComponent, {
			data: {
				...config
			}
		});
	}
}
